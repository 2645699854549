import data from '../data/model.json'

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [ array[i], array[j] ] = [ array[j], array[i] ]
  }

  return array
}

function getShuffledImages(maxLength = 10) {
  const images = []
  const addedImages = new Set()

  while (images.length < maxLength) {
    for (const model of data.models) {
      const shuffledAlbum = shuffleArray(model.album)
      for (const image of shuffledAlbum) {
        if (!addedImages.has(image)) {
          images.push(image)
          addedImages.add(image)
          if (images.length === maxLength) break
        }
      }
      if (images.length === maxLength) break
    }
  }

  return images
}

function openClickURL() {
  window.open(data.clickURL, '_blank')
}

export {
  shuffleArray,
  openClickURL,
  getShuffledImages
}
