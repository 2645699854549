import React, { useEffect, useState } from 'react'
import { getShuffledImages } from '../hooks/utils'
import ArtistImageCard from '../components/ArtistImageCard'

function Custom() {
  const [ catalog, setCatalog ] = useState([])

  useEffect(() => {
    const images = getShuffledImages(20)
    setCatalog(images)
  }, [])

  return (
      <div className="mx-4">
        <div className="my-2 grid sm:grid-cols-6 grid-cols-2 gap-2">
          {catalog.map((photo, index) => <ArtistImageCard key={index} url={photo}/>)}
        </div>
      </div>
  )
}

export default Custom
