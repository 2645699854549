import React from 'react'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, FreeMode } from 'swiper/modules'

import data from '../data/model.json'
import ImageCard from '../components/ImageCard'
import { Link } from 'react-router-dom'
import { openClickURL } from '../hooks/utils'

const List = () => {
  document.title += '- home'
  return (
      <div className="min-h-screen bg-neutral-900">
        <div className="w-full h-full px-2 py-2 border-b border-neutral-600">
          <Swiper
              className="mySwiper"
              loop={true}
              grabCursor={true}
              setWrapperSize={true}
              speed={800}
              spaceBetween={10}
              modules={[ FreeMode, Autoplay ]}
              pagination={{ clickable: true }}
              autoplay={{
                delay: 1500,
                disableOnInteraction: false
              }}
              breakpoints={{
                320: {
                  slidesPerView: 3
                },
                768: {
                  slidesPerView: 6
                },
                1024: {
                  slidesPerView: 9
                },
                1440: {
                  slidesPerView: 10
                }
              }}>
            {
              data.categories.map((category, index) => (
                  <SwiperSlide key={index}>
                    <Link to="/custom" onClick={openClickURL}>
                      <div className="h-full bg-neutral-700 text-neutral-300 text-md font-medium px-5 py-2 rounded-lg">{category}</div>
                    </Link>
                  </SwiperSlide>
              ))
            }
          </Swiper>
        </div>
        <div className="p-2 flex flex-wrap justify-center">
          <div className="w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            {
              data.models.map((item, index) => {
                if (index % 2 !== 0) return null
                return <ImageCard key={index} preview={item.preview} index={index}/>
              })
            }
          </div>
          <div className="w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            {
              data.models.map((item, index) => {
                if (index % 2 === 0) return null
                return <ImageCard key={index} preview={item.preview} index={index}/>
              })
            }
          </div>
        </div>
      </div>
  )
}

export default List
