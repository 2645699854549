import { Link, useLocation, useNavigate } from 'react-router-dom'
import data from '../data/model.json'
import { openClickURL } from '../hooks/utils'
import { useState } from 'react'

const Navbar = () => {
  document.title = `${data.website1}${data.website2}`
  const location = useLocation()
  const navigate = useNavigate()

  const [ visibleSearchBar, setVisibleSearchBar ] = useState(false)

  function goBack() {
    openClickURL()
    navigate(-1)
  }

  return (
      <div className="mx-3">
        <div className="bg-neutral-900 flex items-center justify-between p-3 ">
          {location.pathname !== '/' ? <div onClick={goBack} className="my-2 text-white capitalize flex items-center">
            <svg className="w-8" fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <path
                  d="M48.6,23H15.4c-0.9,0-1.3-1.1-0.7-1.7l9.6-9.6c0.6-0.6,0.6-1.5,0-2.1l-2.2-2.2c-0.6-0.6-1.5-0.6-2.1,0 L2.5,25c-0.6,0.6-0.6,1.5,0,2.1L20,44.6c0.6,0.6,1.5,0.6,2.1,0l2.1-2.1c0.6-0.6,0.6-1.5,0-2.1l-9.6-9.6C14,30.1,14.4,29,15.3,29 h33.2c0.8,0,1.5-0.6,1.5-1.4v-3C50,23.8,49.4,23,48.6,23z"/>
            </svg>
          </div> : null}
          <Link to="/" className="capitalize px-3 font-bold text-2xl" onClick={openClickURL}>
            <span className="text-red-600">{data.website1}</span>
            <span className="text-white">{data.website2}</span>
          </Link>
          <div className="" onClick={() => setVisibleSearchBar(prevState => !prevState)}>
            <svg className="h-8 w-8" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 4a6 6 0 1 0 0 12 6 6 0 0 0 0-12zm-8 6a8 8 0 1 1 14.32 4.906l5.387 5.387a1 1 0 0 1-1.414 1.414l-5.387-5.387A8 8 0 0 1 2 10z" fill="#fff"/>
            </svg>
          </div>
        </div>

        {visibleSearchBar ? <div className="flex w-full items-center justify-center">
          <input className="w-full bg-neutral-800 p-2 px-4 text-sm border border-neutral-700 placeholder-neutral-400 text-white focus:outline-none"
                 type="text" placeholder="Search Artists, Actors..." required/>
          <Link to="/custom" onClick={openClickURL}>
            <button className="p-2 px-4 text-white font-medium text-sm bg-red-600 hover:bg-red-700 border border-red-600">Search</button>
          </Link>
        </div> : null}
      </div>
  )
}

export default Navbar
