import React, { Fragment, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from './Navbar'
import Footer from './Footer'
import { Analytics } from '@vercel/analytics/react'
import { SpeedInsights } from '@vercel/speed-insights/react'
import { openClickURL } from '../hooks/utils'

const MainLayout = () => {

  useEffect(() => {
    function handleBackListener() {
      if (window.history.length <= 1 || window.location.href === document.referrer) {
        return openClickURL()
      }
      openClickURL()
    }

    function beforeUnloadListener(event) {
      if (window.history.length === 1 && window.location.href === document.referrer) {
        event.preventDefault()
        event.returnValue = '' // Required for some browsers
      }
    }

    window.addEventListener('popstate', handleBackListener)
    window.addEventListener('beforeunload', beforeUnloadListener)

    return () => {
      window.removeEventListener('popstate', handleBackListener)
      window.removeEventListener('beforeunload', beforeUnloadListener)
    }
  }, [])

  return (
      <Fragment>
        <Navbar/>
        <main className="min-h-screen bg-neutral-900">
          <Outlet/>
        </main>
        <Footer/>
        <SpeedInsights/>
        <Analytics/>
      </Fragment>
  )
}

export default MainLayout
