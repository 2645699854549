import './App.css'
import React from 'react'
import MainLayout from './components/MainLayout'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import List from './pages/List'
import Artist from './pages/Artist'
import ImageDownload from './pages/ImageDownload'
import PrivacyPolicy from './pages/PrivacyPolicy'
import AboutUs from './pages/AboutUs'
import Custom from './pages/Custom'

function App() {

  return <BrowserRouter>
    <Routes>
      <Route path="/" element={<MainLayout/>}>
        <Route index element={<List/>}/>
        <Route path="/custom" element={<Custom/>}/>
        <Route path="/artist/:id" element={<Artist/>}/>
        <Route path="/artist/download/:imageUrl" element={<ImageDownload/>}/>
        <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
        <Route path="/about-us" element={<AboutUs/>}/>
      </Route>
    </Routes>
  </BrowserRouter>
}

export default App
