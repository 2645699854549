import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { openClickURL } from '../hooks/utils'

function ImageDownload() {
  document.title += `- Click to view`
  const { imageUrl } = useParams()
  const navigate = useNavigate()

  const url = decodeURIComponent(imageUrl)

  const [ isBlur, setIsBlur ] = useState(true)

  function goBack() {
    openClickURL()
    navigate(-1)
  }

  function forceDownload() {
    openClickURL()
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.responseType = 'blob'
    xhr.onload = function() {
      const urlCreator = window.URL || window.webkitURL
      const imageUrl = urlCreator.createObjectURL(this.response)
      const tag = document.createElement('a')
      tag.href = imageUrl
      tag.download = new Date().valueOf() + '.png'
      document.body.appendChild(tag)
      tag.click()
      document.body.removeChild(tag)
    }
    xhr.send()
  }

  function handleRevealImage() {
    openClickURL()
    setIsBlur(false)
  }

  return (
      <div className="bg-neutral-900 mb-12 p-4 h-fit cursor-pointer flex flex-col items-center justify-center">
        <div className="h-fit">
          <img className={`overflow-hidden rounded-xl object-contain ${isBlur ? 'blur-sm' : 'blur-0'}`} src={url} alt="download artist" onClick={handleRevealImage}/>
        </div>

        <button onClick={forceDownload}
                className="my-2 flex items-center justify-center px-5 py-2 w-full focus:outline-none text-white focus:ring-4 font-medium rounded-lg text-sm bg-green-700 hover:bg-green-700 focus:ring-gray-800">
          <svg className="mr-2 w-6" fill="#fff" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" fill="#fff" clipRule="evenodd"
                  d="M7 9.35801V1H8V9.29289L10.1464 7.14645L10.8536 7.85355L7.51386 11.1932L3.91086 7.8674L4.58914 7.1326L7 9.35801ZM2 13V7H1V14H14V7H13V13H2Z"/>
          </svg>
          Download
        </button>

        <button type="button" onClick={goBack}
                className="flex items-center justify-center px-5 py-2 w-full focus:outline-none text-white focus:ring-4 font-medium rounded-lg text-sm bg-neutral-800 hover:bg-neutral-700 focus:ring-gray-800">
          <svg className="mr-2 w-6" fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <path d="M48.6,23H15.4c-0.9,0-1.3-1.1-0.7-1.7l9.6-9.6c0.6-0.6,0.6-1.5,0-2.1l-2.2-2.2c-0.6-0.6-1.5-0.6-2.1,0
	L2.5,25c-0.6,0.6-0.6,1.5,0,2.1L20,44.6c0.6,0.6,1.5,0.6,2.1,0l2.1-2.1c0.6-0.6,0.6-1.5,0-2.1l-9.6-9.6C14,30.1,14.4,29,15.3,29
	h33.2c0.8,0,1.5-0.6,1.5-1.4v-3C50,23.8,49.4,23,48.6,23z"/>
          </svg>
          Back
        </button>
      </div>
  )
}

export default ImageDownload
