import { Link } from 'react-router-dom'
import { openClickURL } from '../hooks/utils'

const Footer = () => {

  return <div className="p-4 w-full text-sm flex justify-around border-t border-neutral-600">
    <Link to="/privacy-policy" onClick={openClickURL}>
      <span
          className="border focus:outline-none focus:ring-4 font-medium rounded-full text-sm px-5 py-2.5 mr-2 bg-neutral-800 text-white border-neutral-600 hover:bg-neutral-700 hover:border-neutral-600 focus:ring-neutral-700">Privacy Policy</span>
    </Link>
    <Link to="/about-us" onClick={openClickURL}>
      <span
          className="border focus:outline-none focus:ring-4 font-medium rounded-full text-sm px-5 py-2.5 bg-neutral-800 text-white border-neutral-600 hover:bg-neutral-700 hover:border-neutral-600 focus:ring-neutral-700">AboutUs</span>
    </Link>
  </div>
}

export default Footer
