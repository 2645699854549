import React from 'react'
import data from '../data/model.json'

function PrivacyPolicy() {
  document.title += `- privacy policy`
  return (
      <div className="min-h-screen h-full bg-neutral-900 px-8 py-10">
        <h1 className="pb-4 w-full text-3xl text-white font-black" style={{ fontFamily: 'Noto Sans, sans-serif' }}>{data.footer[0].title}</h1>
        <p className="pb-8 font-normal text-neutral-400">{data.footer[0].privacyContent}</p>
      </div>
  )
}

export default PrivacyPolicy

