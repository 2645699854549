import React from 'react'
import { useParams } from 'react-router-dom'
import data from '../data/model.json'

import ArtistImageCard from '../components/ArtistImageCard'

function Artist() {
  const { id } = useParams()
  const artist = data.models[id]
  document.title += `- ${artist?.description}`

  return (
      <div className="mx-4">
        <p className="ml-3 text-lg font-normal text-neutral-300 line-clamp-4 overflow-hidden">{artist?.description}</p>
        <div className="my-2 grid sm:grid-cols-6 grid-cols-2 gap-2">
          {artist.album.map((photo, index) => <ArtistImageCard key={index} url={photo}/>)}
        </div>
      </div>
  )
}

export default Artist
