import { Link } from 'react-router-dom'
import { openClickURL } from '../hooks/utils'

const ArtistImageCard = ({ url }) => {

  return (
      <div className="overflow-hidden rounded-xl col-span-2 lg:col-span-1 border-6 border-red-600 cursor-pointer">
        <Link to={`/artist/download/${encodeURIComponent(url)}`}  onClick={openClickURL}>
          <img className="h-full w-full object-cover" src={url} alt="artist"/>
        </Link>
      </div>
  )
}

export default ArtistImageCard
