import React from 'react'
import data from '../data/model.json'

function AboutUs() {
  document.title += `- about us`

  return (
      <div className="min-h-screen  h-full bg-neutral-900 px-8 py-10">
        <h1 className="pb-4 w-full text-3xl text-white font-black" style={{ fontFamily: 'Noto Sans, sans-serif' }}>{data.footer[1].title}</h1>
        {data.footer[1].aboutUsContent.split('\n').map((paragraph, index) => <p key={index} className="mb-3 font-normal text-neutral-400">{paragraph}</p>)}
      </div>
  )
}

export default AboutUs
