import { Link } from 'react-router-dom'
import { openClickURL } from '../hooks/utils'

const ImageCard = ({ preview, index }) => {

  return <Link to={`/artist/${index}`} onClick={openClickURL}>
    <img className="w-full p-1 rounded-lg" src={preview} alt={preview}/>
  </Link>
}

export default ImageCard
